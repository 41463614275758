import {useEffect} from 'react';

export const updatePageTitle = (title: string) => {
  if (title) document.title = title;
};

export default (title: string) => {
  useEffect(() => {
    updatePageTitle(title);
  }, [title]);
};
