// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Enums/AcademyContentStatus.cs

import {z} from 'zod';

export const AcademyContentStatusSchema = z.union([
  z.literal(0),
  z.literal(1),
  z.literal(2),
  z.literal(3),
  z.literal(4),
  z.literal(5),
]);
