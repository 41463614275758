import {
  NewWindowFragment,
  SourceLxpFragment,
  SsoProviderDegreed,
} from '@utils/ClientPaths';

/**
 * LXP links append source=lxp, ssoProvider=degreed or newWindow=true to the URL to indicate the user is from an external source
 */
export const useUrlContainsIndicator = (href: string) => {
  const indicators = [NewWindowFragment, SourceLxpFragment, SsoProviderDegreed];
  const hrefCaseNormalized = href.toLowerCase();
  for (let i = 0; i < indicators.length; i++) {
    const indicator = indicators[i];
    const indicatorCaseNormalized = indicator.toLowerCase();
    if (hrefCaseNormalized.indexOf(indicatorCaseNormalized) > -1) {
      return true;
    }
  }
  return false;
};
