import {useDebugValue} from 'react';
import {
  FeatureFlagTreatments,
  getLocalHostTreatment,
  isLDFeatureFlagOn,
} from '@utils/feature-flag-helpers';
import {useLDClient} from 'launchdarkly-react-client-sdk';
import {isLocal} from '@utils/environment-helpers';
import {useFlagContext} from '@blocks/featureFlagInit/SplitProvider';

/**
 * From https://www.split.io/blog/react-hooks-feature-flags/
 * Example usage `const allowDelete = useTreatment(DELETE_TREATMENT) === 'on';`
 * @param treatmentName
 */
export default function useTreatment(
  treatmentName: string
): FeatureFlagTreatments {
  const {client, isLaunchDarkly} = useFlagContext();

  // New Launch Darkly treatment
  if (isLaunchDarkly) {
    if (isLocal) {
      return getLocalHostTreatment(treatmentName);
    }
    const ldClient = useLDClient();
    return isLDFeatureFlagOn(ldClient?.variation(treatmentName));
  }

  // Split.io treatment to be removed after Launch Darkly migration
  const treatmentValue = client?.getTreatment(
    treatmentName
  ) as FeatureFlagTreatments;
  useDebugValue(`${treatmentName}: ${treatmentValue}`);
  return treatmentValue;
}
