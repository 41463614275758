import {AdminPaths, UserPaths} from '@utils/ClientPaths';
import {Navigate, useLocation} from 'react-router-dom';

const AdminWrapper = ({
  isAdmin,
  children,
}: {
  isAdmin: boolean;
  children: React.ReactNode;
}) => {
  const location = useLocation();
  if (!isAdmin) {
    const userPath = location.pathname.replace(
      AdminPaths.Index,
      UserPaths.Index
    );
    if (
      location.pathname.includes('programs/custom-programs') &&
      location.pathname.includes('submission')
    ) {
      return (
        <Navigate
          to={userPath.replace(
            'programs/custom-programs',
            'permissions/programs'
          )}
          replace
        />
      );
    }
    return <Navigate to={userPath} replace />;
  }

  return <>{children}</>;
};

export default AdminWrapper;
