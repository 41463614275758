import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryResult,
} from 'react-query';
import {
  simpleQueryFn,
  simpleInvalidateExactQueryFn,
  simpleSetQueryDataFn,
} from '@store/queryClient';
import {ReactQueryRequest} from '@store/apiEndpoints';
import queryClient, {
  simpleMutationFn,
  simpleDeleteFn,
  simplePutFn,
  simplePatchFn,
} from '@store/queryClient';
import {AxiosError} from 'axios';
import querystring from 'querystring';
import hookNotificationMiddleware from './middleware/hookNotificationMiddleware';
import {logMockData} from './mock-utils';
import {isLocal, isStagingAny, environment} from '@utils/environment-helpers';
import {ZodSchema} from 'zod';
import {datadogLogs} from '@datadog/browser-logs';

/*
|--------------------------------------------------------------------------
| Query Hook Factory
|--------------------------------------------------------------------------
*/

interface QueryHookOptions<ReturnData> {
  defaultQueryOptions?: UseQueryOptions<ReturnData>;
  onSuccessNotification?: (data: ReturnData) => void;
  onErrorNotification?: (error: any) => void;
}

export type QueryHookReturnType<ReturnData> = UseQueryResult<ReturnData> & {
  invalidateExact: () => void;
  cacheData: ReturnData | undefined;
};

export function queryHookFactory<PathVars, ReturnData>(
  hookName: string, // can be used for middleware
  apiEndpointRq: (pathVars: PathVars) => ReactQueryRequest | string,
  schemas: {
    responseSchema?: ZodSchema | null;
    pathVarsSchema?: ZodSchema | null;
  } = {}
) {
  return (
    pathVars: PathVars,
    queryOptions?: UseQueryOptions<ReturnData> & {
      component?: string;
      mockResponseData?: any;
    }
  ): QueryHookReturnType<ReturnData> => {
    const queryRqOrPath = apiEndpointRq(pathVars);

    // This handles legacy apiEndpoint functions and simple url functions
    const isApiFunctionSimple = typeof queryRqOrPath === 'string';
    const path = !isApiFunctionSimple ? queryRqOrPath.path : queryRqOrPath;
    const queryKey = !isApiFunctionSimple
      ? queryRqOrPath.queryKey
      : queryRqOrPath.split('/');

    // Uncomment the next line to inspect the path in the console
    // console.log(pathVars, path);
    const defaultEnabledWithValidPathVars =
      pathVars === null ||
      Object.values(pathVars || {}).every((v) => v !== undefined);

    const queryFn = queryOptions?.mockResponseData
      ? () => queryOptions.mockResponseData
      : () => simpleQueryFn(path);
    if (queryOptions?.mockResponseData && (isStagingAny || isLocal)) {
      logMockData(path, pathVars, hookName, queryOptions);
    }

    const query = useQuery<ReturnData>(queryKey, queryFn, {
      // default enabled is true when all path vars are defined
      enabled: defaultEnabledWithValidPathVars,

      // Instance options
      ...queryOptions,

      onSuccess: (data) => {
        if (schemas.responseSchema) {
          try {
            const validation = schemas.responseSchema.safeParse(data);

            if (validation.error) {
              logApiEnterfaceError({
                hookName,
                path,
                method: 'get',
                errors: validation.error.errors,
                data,
              });
              if (isLocal || isStagingAny) {
                console.warn(
                  `*** Validation Error: ${hookName} - ${path}`,
                  validation.error.errors,
                  data
                );
              }
            }
          } catch (error) {
            console.log(error);
          }
        }

        // Show notification
        hookNotificationMiddleware(hookName, 'success');

        // Call instance onSuccess
        queryOptions?.onSuccess?.(data);
      },

      onError: (error: AxiosError) => {
        // 404's are not "errors", but signal that data was not found for the query
        // We want to update the cache to undefined in this case
        if (error.response?.status === 404) {
          simpleSetQueryDataFn(queryKey, undefined);
        } else {
          // Show notification
          hookNotificationMiddleware(hookName, 'error');
        }

        // Call instance onError
        queryOptions?.onError?.(error);
      },
    });

    // Helper function to mark the data as stale and refetch lazily
    const invalidateExact = async () =>
      await simpleInvalidateExactQueryFn(queryKey);

    return {
      ...query,
      invalidateExact,
      cacheData: queryClient.getQueryData(queryKey),
    };
  };
}

/*
|--------------------------------------------------------------------------
| Query Mutation Factory
|--------------------------------------------------------------------------
*/

interface MutationHookOptions<ReturnData, Payload> {
  defaultMutationOptions?: UseMutationOptions<ReturnData, unknown, Payload>;
  onSuccessNotification?: (
    data?: ReturnData,
    variables?: Payload,
    context?: unknown
  ) => void;
  onErrorNotification?: (
    data?: ReturnData,
    variables?: Payload,
    context?: unknown
  ) => void;
}

export interface HookMutationArgs<Payload, PathVars> {
  payload?: Payload;
  pathVars?: PathVars;
}

export function mutationHookFactory<
  Payload,
  PathVars = undefined,
  ReturnData = undefined
>(
  hookName: string,
  method: 'put' | 'post' | 'delete' | 'patch',
  pathOrPathGetter: string | ((args: PathVars) => string),
  schemas: {
    payloadSchema?: ZodSchema | null;
  } = {}
) {
  return (
    mutationOptions?: UseMutationOptions<
      ReturnData,
      unknown,
      HookMutationArgs<Payload, PathVars>
    >
  ): UseMutationResult<
    ReturnData,
    unknown,
    {payload?: Payload; pathVars?: PathVars} // same as HookMutationArgs<Payload, PathVars>, but more readable in the linter
  > => {
    return useMutation(
      ({payload, pathVars}: HookMutationArgs<Payload, PathVars>) => {
        const path =
          typeof pathOrPathGetter === 'string'
            ? pathOrPathGetter
            : pathOrPathGetter(pathVars);

        try {
          if (payload && schemas.payloadSchema) {
            const validation = schemas.payloadSchema.safeParse(payload);

            if (validation.error) {
              logApiEnterfaceError({
                hookName,
                path,
                method,
                errors: validation.error.errors,
                data: payload,
              });

              if (isLocal || isStagingAny) {
                console.warn(
                  `**** Validation Error: ${hookName} - ${path}`,
                  validation.error.errors,
                  payload
                );
              }
            }
          }
        } catch (error) {
          console.log(error);
        }

        const mutationFn = (() => {
          switch (method) {
            case 'post':
              return simpleMutationFn;
            case 'put':
              return simplePutFn;
            case 'delete':
              return simpleDeleteFn;
            case 'patch':
              return simplePatchFn;
          }
        })();
        return mutationFn<ReturnData>(path, payload);
      },
      {
        // Instance Options
        ...mutationOptions,

        onSuccess: (data, variables, context) => {
          // Show Notification
          hookNotificationMiddleware(hookName, 'success');

          // Call instance onSuccess
          mutationOptions?.onSuccess?.(data, variables, context);
        },
        onError: (error: AxiosError, variables, context) => {
          // Show notification
          hookNotificationMiddleware(hookName, 'error');

          // Call instance onError
          mutationOptions?.onError?.(error, variables, context);
        },
      }
    );
  };
}

/*
|--------------------------------------------------------------------------
| Util Methods
|--------------------------------------------------------------------------
*/

export const addQueryParams = (
  endpointFunction: (args: any) => string,
  pathVars: Record<string, string | number> | null,
  queryParams?: any // Record<string, string | number | boolean | number[] | string[]>
): string => {
  let url = endpointFunction(pathVars);
  if (!!queryParams && Object.keys(queryParams).length) {
    const queryParamsStr = querystring.stringify(queryParams);
    url = url + '?' + queryParamsStr;
  }
  return url;
};

function logApiEnterfaceError(info: {
  hookName: string;
  path: string;
  errors: any;
  method: string;
  data: any;
}) {
  datadogLogs.logger.error(`api interface error`, {...info, environment});
}
