import {Id} from './typeUtils';
import {PlanItemType} from '@generated/enums';

/*
|--------------------------------------------------------------------------
| Top Level Routes
|--------------------------------------------------------------------------
*/

export enum Areas {
  User = '/user',
  Manager = '/manager',
  Admin = '/admin',
  Integrated = '-/',
  Approvals = '/approvals',
}

export enum UserPaths {
  Index = '/user',
  Wildcard = '/user/*',
  Academies = '/user/academies',
  AcademiesWildcard = '/user/academies/*',
  ViewAcademies = '/user/academies/view',
  WelcomeAcademy = '/user/academies/welcome',
  ProgramsSearch = '/user/programs',
  ProgramDetail = '/user/program-detail',
  ProgramFinder = '/user/program-finder',
  ProgramFinderWildcard = '/user/program-finder/*',
  TimeIncentivesSearch = '/user/time',
  FinanceIncentivesSearch = '/user/financing',
  FinanceIncentivesDetail = '/user/finance-detail/:id',
  ManagePlan = '/user/manage',
  ManagePlanPrograms = '/user/manage/program',
  ManagePlanTimeIncentives = '/user/manage/time',
  ManagePlanFinanceIncentives = '/user/manage/finance',
  Feedback = '/user/feedback',
  Settings = '/user/settings',
  Permissions = '/user/permissions',
}

export enum ManagerPaths {
  Index = '/manager',
  Wildcard = '/manager/*',
  ApprovalsWildcard = '/manager/approvals/*',
  ApprovalsProgress = '/manager/approvals/progress',
  ApprovalsProgram = '/manager/approvals/program',
  ApprovalsTime = '/manager/approvals/time',
  ApprovalsFinance = '/manager/approvals/finance',
  ApprovalsResource = '/manager/approvals/resource',
  TeamInsights = '/manager/team-insights',
}

export enum AdminPaths {
  Index = '/admin',
  Wildcard = '/admin/*',
  InvestmentsIncentives = '/admin/investments/incentives',
  ApprovalsProgram = '/admin/approvals/program',
  ApprovalsTime = '/admin/approvals/time',
  ApprovalsFinance = '/admin/approvals/finance',
  ApprovalsResource = '/admin/approvals/resource',
  Reports = '/admin/reports',
  Programs = '/admin/programs',
  Academies = '/admin/academies',
  Initiatives = '/admin/initiatives',
  Licenses = '/admin/licenses',
  Prepayment = '/admin/prepayment',
  Qa = '/admin/qa',
  DeveloperAdmin = '/admin/developer-admin',
  People = '/admin/people',
  NewAcademy = '/admin/academies/new',
}

export type ClientPathsType = UserPaths | ManagerPaths | AdminPaths | '#';

/*
|--------------------------------------------------------------------------
| Fragments
|--------------------------------------------------------------------------
*/

export const CustomProgramPathFragment = ':customProgramId/view/*';
export const ProgramDetailPathFragment = 'program-detail/:id/:academyId?';

/*
|--------------------------------------------------------------------------
| URL Fragments
|--------------------------------------------------------------------------
*/

export const NewWindowFragment = 'newWindow=true';
export const SourceLxpFragment = 'source=lxp';
export const SsoProviderDegreed = 'ssoProvider=degreed';

/*
|--------------------------------------------------------------------------
| Tab Keys and Screens
|--------------------------------------------------------------------------
*/

export enum AdminProgramPageTabKey {
  MarketPlacePrograms = 'marketplace-programs',
  CustomPrograms = 'custom-programs',
}

export enum UserProgramsPageTab {
  MarketPlace = 'marketplace-programs',
  Custom = `my-company-programs`,
}

export enum ManagedPermissionsPageTabKey {
  Programs = 'programs',
  Academies = 'academies',
}

export enum CustomProgramScreens {
  Setup = 'Setup',
  Curriculum = 'Curriculum',
  Projects = 'Projects',
  Reports = 'Reports',
  Agenda = 'Agenda',
  Permissions = 'Permissions',
  Content = 'Content',
}

export enum AcademyScreens {
  setup = 'setup',
  branding = 'branding',
  overview = 'overview',
  drive = 'drive',
  people = 'people',
  permissions = 'permissions',
  reports = 'reports',
  projects = 'projects',
}

export enum AcademyPeoplePageTabs {
  Leadership = 'leadership',
  Participants = 'participants',
}

export enum AcademyReportsPageTabs {
  Participants = 'participants',
  Sections = 'sections',
}

export enum AcademyReportsSectionPaths {
  Step = 'step',
}

export enum EmailPreviewPaths {
  Index = 'email-preview/',
  Wildcard = 'email-preview/*',
}

/*
|--------------------------------------------------------------------------
| Drawer Routes
|--------------------------------------------------------------------------
*/

export enum DrawerRoutes {
  BulkNudge = 'bulk-nudge',
  Nudge = 'nudge',
}

/*
|--------------------------------------------------------------------------
| Nested Routes
|--------------------------------------------------------------------------
*/

// Admin Programs
export const adminProgramsCustomProgramPreview = (
  customProgramId: number,
  screen: string,
  searchParams?: URLSearchParams
) => {
  const searchParamsUrl = searchParams ? `?${searchParams.toString()}` : '';
  return `/admin/programs/custom-programs/${customProgramId}/view/${screen.toLowerCase()}${searchParamsUrl}`;
};

export const adminProgramsCustomProgramManage = (
  customProgramId: number,
  screen: CustomProgramScreens
) => {
  return `/admin/programs/custom-programs/${customProgramId}/edit/${screen.toLowerCase()}`;
};

export const adminProgramsCustomProgramPreviewProjectSubmissions = (
  customProgramId: number,
  projectId: number
) => {
  return [
    adminProgramsCustomProgramPreview(
      customProgramId,
      CustomProgramScreens.Projects
    ),
    projectId,
    'submissions',
  ].join('/');
};

export function adminCustomProgramScreenRoute(
  id: number,
  screen: CustomProgramScreens,
  searchParams?: URLSearchParams
) {
  const searchParamsUrl = searchParams?.toString()
    ? `?${searchParams.toString()}`
    : '';
  return `${adminProgramsCustomRoute()}/${id}/view/${String(
    screen
  ).toLowerCase()}${searchParamsUrl}`;
}

// User Programs
export const getUserProgramsBasePath = (tab: UserProgramsPageTab) => {
  return [UserPaths.ProgramsSearch, tab].join('/');
};

export const getUserProgramsCustomProgramUserDrawer = (
  customProgramId: number,
  screen: CustomProgramScreens,
  from = 'custom-programs'
) => {
  return [
    getUserProgramsBasePath(UserProgramsPageTab.Custom),
    customProgramId,
    'view',
    screen.toLowerCase(),
    from ? `?from=${from}` : '',
  ].join('/');
};

export const YourPlanPaths = [
  UserPaths.ManagePlan,
  UserPaths.ManagePlanPrograms,
  UserPaths.ManagePlanTimeIncentives,
  UserPaths.ManagePlanFinanceIncentives,
];

export const ApprovalsPaths = [
  AdminPaths.ApprovalsFinance,
  AdminPaths.ApprovalsProgram,
  AdminPaths.ApprovalsTime,
  AdminPaths.ApprovalsResource,
  ManagerPaths.ApprovalsProgram,
  ManagerPaths.ApprovalsFinance,
  ManagerPaths.ApprovalsTime,
  ManagerPaths.ApprovalsResource,
];

export const ExploreSubMenuPaths = [
  UserPaths.ProgramsSearch,
  UserPaths.FinanceIncentivesSearch,
  UserPaths.TimeIncentivesSearch,
];

export const getAcademiesProjectsPath = (
  academyId: Id,
  userType: 'user' | 'admin'
) => {
  return `/${userType}/academies/view/${academyId}/projects`;
};

export const getProgramDetailsFromAcademyPath = (
  academyId: Id,
  programId: Id
) => {
  return `/user/program-detail/${programId}/${academyId}`;
};

export const getAcademiesProjectsSubmissionsPath = (
  academyId: Id,
  projectId: Id,
  userType: 'user' | 'admin'
) => {
  return [
    getAcademiesProjectsPath(academyId, userType),
    projectId,
    'submissions',
  ].join('/');
};

export const sendToUserPrograms = (): string => {
  return '/user/programs';
};

export const financeDetailPath = (id: number | string): string => {
  return `/user/manage/finance/${id}`;
};

export const timeDetailPath = (id: number | string): string => {
  return `/user/manage/time/${id}`;
};

export const getFinanceDetailPath = (financeIncentiveId: string | number) => {
  return `/user/finance-detail/${financeIncentiveId}`;
};

export const getCarouselItemDetailPath = (
  programId: string | number,
  type: string
) => {
  return `/user/${type}-detail/${programId}`;
};

export const editAcademyUrl = (id: string | number) =>
  `/admin/academies/configure/${id}/setup`;

export const userEditAcademyUrl = (id: string | number) =>
  `/user/permissions/academies/${id}/configure/setup`;

export const viewAcademyUrl = (
  id: string,
  screen: 'content' | 'reports' | 'projects'
) => `/admin/academies/view/${id}/${screen}`;

export const viewAcademyUrlExternal = (id: string) =>
  `#/admin/academies/view/${id}/content`;

export const generateAdminLevelPath = (academyId: number, levelId: number) =>
  `/admin/academies/configure/${academyId}/overview/${levelId}`;

export const generateUserLevelPath = (academyId: number, levelId: number) =>
  `/user/permissions/academies/${academyId}/configure/overview/${levelId}`;

export function userAcademyRoute(
  id: string | number,
  searchParamsObj?: Record<string, string>
) {
  const searchParams = searchParamsObj
    ? `?${new URLSearchParams(searchParamsObj).toString()}`
    : '';
  return `${UserPaths.ViewAcademies}/${id}/content${searchParams}`;
}

export function adminAcademyReportsRoute(
  academyId: string | number,
  tabKey?: AcademyReportsPageTabs
) {
  let path = `/admin/academies/view/${academyId}/reports`;
  if (tabKey) {
    path += `/${tabKey}`;
  }
  return path;
}

export function getAcademyUserDrawerScreenNavItemUrlPath(
  academyId: number,
  screen: string
) {
  return ['/user', 'academies', 'view', academyId, screen].join('/');
}

export function getAcademyUserDrawerManagePermissionsScreenNavItemUrlPath(
  academyId: number,
  screen: string
) {
  return [
    getManagerPermissionsRoute(ManagedPermissionsPageTabKey.Academies),
    academyId,
    'view',
    screen,
  ].join('/');
}

export function getAcademyUserDrawerAdminPreviewScreenNavItemUrlPath(
  academyId: number,
  screen: string
) {
  return ['/admin', 'academies', 'view', academyId, screen].join('/');
}

export function userAcademiesRoute() {
  return '/user/academies';
}

export function userWelcomeAcademyRoute(id: string | number) {
  return `${UserPaths.WelcomeAcademy}/${id}`;
}

export function userProgramDetailRoute(id: string | number) {
  return `${UserPaths.ProgramDetail}/${id}`;
}

export const IsPreviewQueryParam = 'is_preview';

export function userCustomProgramDetailRoute(
  id: string | number,
  isPreview: boolean,
  additionalParams: Record<string, string> = {}
) {
  const preResult = `/user/custom-program/${id}`;

  const searchParams = new URLSearchParams(additionalParams);
  if (isPreview) {
    searchParams.set(IsPreviewQueryParam, 'true');
  }

  return Array.from(searchParams.keys()).length > 0
    ? `${preResult}?${searchParams.toString()}`
    : preResult;
}

export const adminProgramsMarketplaceRoute = () => {
  return `/admin/programs/${AdminProgramPageTabKey.MarketPlacePrograms}`;
};

export const adminProgramsCustomRoute = () => {
  return `/admin/programs/${AdminProgramPageTabKey.CustomPrograms}`;
};

export const adminProgramsPageRoute = (tab: AdminProgramPageTabKey) => {
  return `/admin/programs/${tab}`;
};

// Manager Manage Permissions Page
export function getManagerPermissionsRoute(tab: ManagedPermissionsPageTabKey) {
  return [UserPaths.Permissions, tab].join('/');
}

export const getManagerPermissionsCustomProgramAdminDrawerRoute = (
  customProgramId: number,
  screen: CustomProgramScreens
) => {
  return [
    getManagerPermissionsRoute(ManagedPermissionsPageTabKey.Programs),
    customProgramId,
    'configure',
    String(screen)?.toLowerCase(),
  ].join('/');
};

export const getPermissionCohortAdminDrawerRoute = (
  cohortId: number,
  parentCustomProgramId: number
) => {
  return [
    getManagerPermissionsRoute(ManagedPermissionsPageTabKey.Programs),
    parentCustomProgramId,
    'cohort',
    cohortId,
  ].join('/');
};

export const getManagerPermissionsAcademyAdminDrawerRoute = (
  academyId: number,
  screen: AcademyScreens,
  levelId?: number,
  _new?: boolean
) => {
  return [
    getManagerPermissionsRoute(ManagedPermissionsPageTabKey.Academies),
    academyId,
    'configure',
    String(screen)?.toLowerCase(),
    levelId,
    _new && 'new',
  ]
    .filter(Boolean)
    .join('/');
};

export const getManagerPermissionsCustomProgramUserDrawerRoute = (
  customProgramId: number,
  screen: CustomProgramScreens
) => {
  return [
    getManagerPermissionsRoute(ManagedPermissionsPageTabKey.Programs),
    customProgramId,
    'view',
    String(screen)?.toLowerCase(),
  ].join('/');
};

export const getManagerPermissionsAcademyUserDrawerRoute = (
  academyId: number,
  screen: CustomProgramScreens
) => {
  return [
    getManagerPermissionsRoute(ManagedPermissionsPageTabKey.Academies),
    academyId,
    'view',
    String(screen).toLowerCase(),
  ].join('/');
};

export const getAdminAcademyConfigureDrawerRoute = (
  academyId: number | string,
  screen: AcademyScreens,
  levelId?: number,
  _new?: boolean
) => {
  return [
    AdminPaths.Academies,
    'configure',
    academyId,
    screen,
    levelId,
    _new && 'new',
  ]
    .filter(Boolean)
    .join('/');
};

export const getUserManageProgramPath = (programId: number | string) =>
  `${UserPaths.ManagePlan}/program/${programId}`;

export const getAdminAcademiesUrl = (): string => {
  return '/admin/academies';
};

export const getAdminAcademiesBrandingUrl = (academyId: number) =>
  [
    getAdminAcademiesUrl(),
    'configure',
    academyId,
    AcademyScreens.branding,
  ].join('/');

export const getAdminAcademiesSetupUrl = (id: number): string => {
  return `/admin/academies/configure/${id}/setup`;
};

export const getEmployeeSearchUrl = (name: string, limit?: number): string => {
  return `user/search?name=${name}&limit=${limit || 10}`;
};

export const getCompanyUsers = (
  companyId: number,
  searchParamsObject: Record<string, string>
): string => {
  const searchParams = new URLSearchParams(searchParamsObject);
  return `companies/${companyId}/users${
    searchParams ? `?${searchParams.toString()}` : ''
  }`;
};

export const getUserPlanItemCardUrl = (
  itemType: PlanItemType,
  id: number | string
): string => {
  let pageName = '';
  switch (itemType) {
    case PlanItemType.Time:
      pageName = 'time';
      break;
    case PlanItemType.Finance:
      pageName = 'finance';
      break;
    case PlanItemType.Program:
      pageName = 'program';
      break;
    default:
      break;
  }

  return `/user/${pageName}-detail/${id}`;
};

export const getProgramDetailPath = (programId: string | number) => {
  return `${UserPaths.ProgramDetail}/${programId}`;
};

export const getAdminProgramDetailPath = (programId: string | number) => {
  return `/admin/program-detail/${programId}`;
};

export const getUserProgramFinderPath = () => {
  return `/user/program-finder`;
};

export const getUserMarketplacePath = () =>
  `/user/programs/${AdminProgramPageTabKey.MarketPlacePrograms}`;

export const getCompanyProgramPath = (
  programId: string | number,
  searchParamsObj?: Record<string, string>
) => {
  const searchParams = searchParamsObj
    ? `?${new URLSearchParams(searchParamsObj).toString()}`
    : '';
  return `/user/programs/my-company-programs/${programId}/view/curriculum${searchParams}`;
};

export const getViewPlanLinkUrl = () => {
  const isLocalHost = window.location.host.includes('localhost');
  return isLocalHost
    ? `/#${UserPaths.ManagePlan}`
    : `/develop/#${UserPaths.ManagePlan}`;
};
