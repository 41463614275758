import {useParamInSessionStorage} from '@hooks/useParamInSessionStorage';

export const useIsLxpAndAcademiesUser = (
  isAcademiesIntegratedExperienceDisabled: boolean,
  isLxpAndAcademiesUserFlagOn: boolean
) => {
  const enabled =
    !isAcademiesIntegratedExperienceDisabled && isLxpAndAcademiesUserFlagOn;
  const paramUsed = useParamInSessionStorage(enabled, 'lxpAndAcademies');
  return enabled && paramUsed;
};
