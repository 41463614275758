// CardIssuerTerms.container.tsx
import {useEffect, useState} from 'react';

import * as React from 'react';
import {isStripeTermsAvailable} from './CardIssuerTerms.shared';
import CardIssuerTermsView from './CardIssuerTerms.view';
import {trackTermsLinkClick} from '@utils/analytics';
import {useAcceptStripeTermsMutation} from '@generated/hooks';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {useGetUserQuery} from '@hooks/apiEndpoints/user/queries';
import {notify} from '@components/user/notifications';

export const onSuccess = (setVisible: React.Dispatch<boolean>) =>
  setVisible(false);

export const onError = (setVisible: React.Dispatch<boolean>) => {
  setVisible(true);
  notify.CardIssuerTermsAcceptanceError();
};

function CardIssuerTermsContainer() {
  // Hooks:
  const {isFeatureFlagOn} = useFeatureFlags();
  const {data: user} = useGetUserQuery();
  const acceptStripeTerms = useAcceptStripeTermsMutation({
    onSuccess: () => onSuccess(setVisible),
    onError,
  });

  // State:
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [visible, setVisible] = useState(
    isStripeTermsAvailable(user?.plbType, user?.hasAcceptedStripeTerms)
  );

  // Functionality:
  const trackExternalLinkClick = () => trackTermsLinkClick('modal');

  const onCheckAcceptedTerms = () => setSubmitDisabled(!submitDisabled);

  const onSubmit = () => {
    user.hasAcceptedStripeTerms = true;
    acceptStripeTerms.mutate({});
  };

  // Effects:
  useEffect(() => {
    setVisible(
      isStripeTermsAvailable(user?.plbType, user?.hasAcceptedStripeTerms)
    );
  }, [isFeatureFlagOn, user?.plbType, user?.hasAcceptedStripeTerms]);

  // View:
  return (
    <CardIssuerTermsView
      visible={visible}
      submitDisabled={submitDisabled}
      onCheckAcceptedTerms={onCheckAcceptedTerms}
      onSubmit={onSubmit}
      trackExternalLinkClick={trackExternalLinkClick}
    />
  );
}

export default CardIssuerTermsContainer;
