import {SplitContext} from '@splitsoftware/splitio-react';
import {ISplitFactoryChildProps} from '@splitsoftware/splitio-react/types/types';
import {FeatureFlagExperiments} from '@utils/feature-flag-helpers';
import {createContext, useContext} from 'react';

interface Props {
  children: JSX.Element | null;
}
interface Context {
  client: Partial<SplitIO.IClient>;
  isLaunchDarkly: boolean;
}
const FlagContext = createContext<Context>({} as Context);

export default function SplitProvider({children}: Props) {
  const {client} = useContext(SplitContext);
  const launchDarklyFlag = client?.getTreatment(
    FeatureFlagExperiments.LaunchDarklyMigration
  );
  const value = {
    client,
    isLaunchDarkly: launchDarklyFlag === 'on',
  };

  return <FlagContext.Provider value={value}>{children}</FlagContext.Provider>;
}

export const useFlagContext = () => useContext(FlagContext);
