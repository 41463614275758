import {AcademyPermissionVM, CustomProgramPermissionVM} from './../interfaces';

import {queryHookFactory} from './../../utils';
import {CustomProgramPermissionVMSchema} from '../zodSchemas/CustomProgramPermissionVMSchema.schema';
import {AcademyPermissionVMSchema} from '../zodSchemas/AcademyPermissionVMSchema.schema';
import {z} from 'zod';

// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Permissions/PermissionController.cs

/*
|--------------------------------------------------------------------------
|  get-managed-academies 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useManagedAcademiesQuery} from '@generated/hooks';
 *
 *  const {
 *    data: managedAcademies,
 *    isLoading: isManagedAcademiesLoading,
 *    invalidateExact: invalidateManagedAcademies,
 *  } = useManagedAcademiesQuery(null)
 *
 */

export const useManagedAcademiesQuery = queryHookFactory<
  null,
  AcademyPermissionVM[] // return type
>('useManagedAcademiesQuery', () => `permission/get-managed-academies`, {
  responseSchema: z.array(AcademyPermissionVMSchema),
});

/*
|--------------------------------------------------------------------------
|  get-managed-programs 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useManagedProgramsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: managedPrograms,
 *    isLoading: isManagedProgramsLoading,
 *    invalidateExact: invalidateManagedPrograms,
 *  } = useManagedProgramsQuery(null)
 *
 */

export const useManagedProgramsQuery = queryHookFactory<
  null,
  CustomProgramPermissionVM[] // return type
>('useManagedProgramsQuery', () => `permission/get-managed-programs`, {
  responseSchema: z.array(CustomProgramPermissionVMSchema),
});
