import {
  ApprovalsDiscriminator,
  ApprovalStatus,
  ApprovalType,
  PlanItemType,
} from './../enums';
import {
  ApprovalRequestVM,
  ResourceApprovalRequestVM,
  UserRequestApprovalDetailsVM,
} from './../interfaces';

import {queryHookFactory, addQueryParams} from './../../utils';
import {ApprovalRequestVMSchema} from '../zodSchemas/ApprovalRequestVMSchema.schema';
import {ResourceApprovalRequestVMSchema} from '../zodSchemas/ResourceApprovalRequestVMSchema.schema';
import {UserRequestApprovalDetailsVMSchema} from '../zodSchemas/UserRequestApprovalDetailsVMSchema.schema';
import {z} from 'zod';

// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Approvals/AdminApprovalsController.cs

/*
|--------------------------------------------------------------------------
|  get-approval-details 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAdminApprovalsApprovalDetailsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: adminApprovalsApprovalDetails,
 *    isLoading: isAdminApprovalsApprovalDetailsLoading,
 *    invalidateExact: invalidateAdminApprovalsApprovalDetails,
 *  } = useAdminApprovalsApprovalDetailsQuery({
 *    queryParams: {
 *        id,
 *        type
 *    }
 *  })
 *
 *  @access Admin Access Only
 *
 */

export const useAdminApprovalsApprovalDetailsQuery = queryHookFactory<
  {queryParams?: {id?: number; type?: ApprovalType}},
  UserRequestApprovalDetailsVM // return type
>(
  'useAdminApprovalsApprovalDetailsQuery',
  ({queryParams}) =>
    addQueryParams(
      () => `admin-approvals/get-approval-details`,
      null,
      queryParams
    ),
  {responseSchema: UserRequestApprovalDetailsVMSchema}
);

/*
|--------------------------------------------------------------------------
|  get-approvals 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAdminApprovalsApprovalsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: adminApprovalsApprovals,
 *    isLoading: isAdminApprovalsApprovalsLoading,
 *    invalidateExact: invalidateAdminApprovalsApprovals,
 *  } = useAdminApprovalsApprovalsQuery({
 *    queryParams: {
 *        status,
 *        type,
 *        useNewApprovalSystem
 *    }
 *  })
 *
 *  @access Admin Access Only
 *
 */

export const useAdminApprovalsApprovalsQuery = queryHookFactory<
  {
    queryParams?: {
      status?: ApprovalStatus;
      type?: PlanItemType;
      useNewApprovalSystem?: boolean;
    };
  },
  ApprovalRequestVM[] // return type
>(
  'useAdminApprovalsApprovalsQuery',
  ({queryParams}) =>
    addQueryParams(() => `admin-approvals/get-approvals`, null, queryParams),
  {responseSchema: z.array(ApprovalRequestVMSchema)}
);

/*
|--------------------------------------------------------------------------
|  get-resource-approvals 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAdminApprovalsResourceApprovalsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: adminApprovalsResourceApprovals,
 *    isLoading: isAdminApprovalsResourceApprovalsLoading,
 *    invalidateExact: invalidateAdminApprovalsResourceApprovals,
 *  } = useAdminApprovalsResourceApprovalsQuery({
 *    queryParams: {
 *        status,
 *        useNewApprovalSystem
 *    }
 *  })
 *
 *  @access Admin Access Only
 *
 */

export const useAdminApprovalsResourceApprovalsQuery = queryHookFactory<
  {queryParams?: {status?: ApprovalStatus; useNewApprovalSystem?: boolean}},
  ResourceApprovalRequestVM[] // return type
>(
  'useAdminApprovalsResourceApprovalsQuery',
  ({queryParams}) =>
    addQueryParams(
      () => `admin-approvals/get-resource-approvals`,
      null,
      queryParams
    ),
  {responseSchema: z.array(ResourceApprovalRequestVMSchema)}
);

/*
|--------------------------------------------------------------------------
|  get-user-request-approvals 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAdminApprovalsUserRequestApprovalsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: adminApprovalsUserRequestApprovals,
 *    isLoading: isAdminApprovalsUserRequestApprovalsLoading,
 *    invalidateExact: invalidateAdminApprovalsUserRequestApprovals,
 *  } = useAdminApprovalsUserRequestApprovalsQuery({
 *    queryParams: {
 *        status,
 *        type
 *    }
 *  })
 *
 *  @access Admin Access Only
 *
 */

export const useAdminApprovalsUserRequestApprovalsQuery = queryHookFactory<
  {queryParams?: {status?: ApprovalStatus; type?: ApprovalsDiscriminator}},
  UserRequestApprovalDetailsVM[] // return type
>(
  'useAdminApprovalsUserRequestApprovalsQuery',
  ({queryParams}) =>
    addQueryParams(
      () => `admin-approvals/get-user-request-approvals`,
      null,
      queryParams
    ),
  {responseSchema: z.array(UserRequestApprovalDetailsVMSchema)}
);

/*
|--------------------------------------------------------------------------
|  get-pending-manager-approvals 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {usePendingManagerApprovalsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: pendingManagerApprovals,
 *    isLoading: isPendingManagerApprovalsLoading,
 *    invalidateExact: invalidatePendingManagerApprovals,
 *  } = usePendingManagerApprovalsQuery({
 *    queryParams: {
 *        useNewApprovalSystem
 *    }
 *  })
 *
 *  @access Admin Access Only
 *
 */

export const usePendingManagerApprovalsQuery = queryHookFactory<
  {queryParams?: {useNewApprovalSystem?: boolean}},
  ApprovalRequestVM[] // return type
>(
  'usePendingManagerApprovalsQuery',
  ({queryParams}) =>
    addQueryParams(
      () => `admin-approvals/get-pending-manager-approvals`,
      null,
      queryParams
    ),
  {responseSchema: z.array(ApprovalRequestVMSchema)}
);

/*
|--------------------------------------------------------------------------
|  get-pending-manager-user-request-approvals 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {usePendingManagerUserRequestApprovalsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: pendingManagerUserRequestApprovals,
 *    isLoading: isPendingManagerUserRequestApprovalsLoading,
 *    invalidateExact: invalidatePendingManagerUserRequestApprovals,
 *  } = usePendingManagerUserRequestApprovalsQuery({
 *    queryParams: {
 *        type
 *    }
 *  })
 *
 *  @access Admin Access Only
 *
 */

export const usePendingManagerUserRequestApprovalsQuery = queryHookFactory<
  {queryParams?: {type?: ApprovalsDiscriminator}},
  UserRequestApprovalDetailsVM[] // return type
>(
  'usePendingManagerUserRequestApprovalsQuery',
  ({queryParams}) =>
    addQueryParams(
      () => `admin-approvals/get-pending-manager-user-request-approvals`,
      null,
      queryParams
    ),
  {responseSchema: z.array(UserRequestApprovalDetailsVMSchema)}
);
