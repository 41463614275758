import {getSplitIOSdkConfig} from '@utils/feature-flag-helpers';
import {SplitFactory} from '@splitsoftware/splitio-react';
import {ISplitFactoryChildProps} from '@splitsoftware/splitio-react/types/types';
import {useGetUserQuery} from '@hooks/apiEndpoints/user/queries';
import SplitProvider from './SplitProvider';

interface Props {
  children: JSX.Element | null;
}

export default function FeatureFlagInit({children}: Props) {
  const getUserQuery = useGetUserQuery();
  if (getUserQuery.isLoading) return null;
  const sdkConfig = getSplitIOSdkConfig(getUserQuery.data.companyId);
  return (
    <SplitFactory config={sdkConfig}>
      <SplitProvider>{children}</SplitProvider>
    </SplitFactory>
  );
}
