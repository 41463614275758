import {UseMutationResult, UseQueryResult} from 'react-query';
import {FormInstance} from 'antd';
import Icon from '@ant-design/icons';
import {ExchangeRatesVM, UserPlanResourceVM} from '@generated/interfaces';
import {LearningResourceType} from '@generated/enums';
import {
  ApplicationQuestionVM,
  RequestResourceApprovalPayload,
  ResourceRerequestApprovalApplicationVM,
} from '@models/serverModels';
import {ResourceOrPlanItemPreApprovalQuestion} from '@models/clientModels';
import {RequestMenuDrawerViewProps} from '../RequestMenuDrawer/RequestMenuDrawer.interfaces';

export interface RequestMenuButtonAndDrawerContainerProps {
  buttonTitle?: string;
  onClickProgramFinder?: () => void;
  rerequestResource?: UserPlanResourceVM;
  showFindProgramOption?: boolean;
  showProgramFinderOption?: boolean;
  showRequestProgramOption?: boolean;
  showRequestResourceOption?: boolean;
  buttonAltStyle?: boolean;
}

export const ProgramRequestType = 'Program';
export type RequestType = LearningResourceType | typeof ProgramRequestType;

export interface RequestMenuButtonAndDrawerViewProps
  extends RequestMenuDrawerViewProps {
  buttonTitle?: string;
  drawerVisible: boolean;
  buttonAltStyle?: boolean;
}

export const enum RequestOptionTypes {
  Book = 'book',
  Conference = 'conference',
  Other = 'other',
  Program = 'program',
  Subscription = 'subscription',
}
