// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Skills/Requests/RemoveSkillTagPayload.cs

import {z} from 'zod';

export const RemoveSkillTagPayloadSchema = z.object({
  entityId: z.number(),
  entityType: z.string(),
  resourceSkillId: z.number(),
  skillTagId: z.number(),
});
