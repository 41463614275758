// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Academies/AcademySkillVM.cs

import {z} from 'zod';
import {LanguagePreferenceSchema} from './LanguagePreferenceSchema.schema';

export const AcademySkillVMSchema = z.object({
  id: z.number(),
  language: LanguagePreferenceSchema,
  name: z.string(),
  title: z.string(),
});
