import {useFlagContext} from '@blocks/featureFlagInit/SplitProvider';
import {isLDFeatureFlagOn} from './../utils/feature-flag-helpers';
import useTreatment from '@hooks/useTreatment';
import {isLocal} from '@utils/environment-helpers';
import {
  FeatureFlagExperiments,
  FeatureFlagTreatments,
  getLocalHostTreatment,
} from '@utils/feature-flag-helpers';
import {useFlags} from 'launchdarkly-react-client-sdk';
/*
|--------------------------------------------------------------------------
| When a feature flag is added, this hook automatically gives access to its status
|--------------------------------------------------------------------------
*/

export type TIsFeatureFlagOn = Record<
  keyof typeof FeatureFlagExperiments,
  boolean
>;

/**
 * @example
 *
 * import useFeatureFlags from '@hooks/useFeatureFlags';
 *
 * const {isFeatureFlagOn} = useFeatureFlags();
 */

const useFeatureFlags = (
  isFlagOnInControlCase?: boolean
  // split.io can return the "control" treatment in certain scenarios
  // When evaluating which treatment to show to a customer,
  // split.io recommends handling the control treatment in the same way as the default treatment.
  // Control Treatment docs: https://help.split.io/hc/en-us/articles/360020528072-Control-treatment
): {isFeatureFlagOn: TIsFeatureFlagOn} => {
  const ldflags = useFlags();
  const {isLaunchDarkly} = useFlagContext();

  // New treatment checks to check if we are using LaunchDarkly or Split.io until Split migration is completed
  const treatment = (flagkey: string) => {
    if (isLaunchDarkly) {
      return isLocal
        ? getLocalHostTreatment(flagkey)
        : isLDFeatureFlagOn(ldflags[flagkey]);
    } else {
      return useTreatment(flagkey);
    }
  };

  const isFeatureFlagOn: any = Object.keys(FeatureFlagExperiments).reduce(
    (flagStatuses, flag: FeatureFlagExperiments) => {
      flagStatuses[flag] = (() => {
        // Rule skipped: This hook is validly calling another hook
        // eslint-disable-next-line react-hooks/rules-of-hooks
        switch (treatment(FeatureFlagExperiments[flag])) {
          case FeatureFlagTreatments.On:
            return true;
          case FeatureFlagTreatments.Off:
            return false;
          case FeatureFlagTreatments.Control:
            return !!isFlagOnInControlCase;
          default:
            return false;
        }
      })();
      return flagStatuses;
    },
    {}
  );
  return {isFeatureFlagOn};
};

export default useFeatureFlags;
