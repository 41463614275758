// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Programs/CustomProgramAdminDetailVM.cs

import {z} from 'zod';
import {ChatExternalChannelVisibilitySchema} from './ChatExternalChannelVisibilitySchema.schema';
import {DurationUnitTypeSchema} from './DurationUnitTypeSchema.schema';
import {SkillTagSchema} from './SkillTagSchema.schema';
import {CustomProgramStatusSchema} from './CustomProgramStatusSchema.schema';

export const CustomProgramAdminDetailVMSchema = z.object({
  chatEnabled: z.boolean(),
  chatExternalChannelName: z.string(),
  chatExternalChannelVisibility: ChatExternalChannelVisibilitySchema,
  cohortCount: z.number(),
  duration: z.string(),
  endDateUtc: z.string().nullable().optional(),
  hasExternalChatId: z.boolean(),
  id: z.number(),
  imageUrl: z.string(),
  isNudgeEmailEnabled: z.boolean(),
  isProgramCompletionEmailEnabled: z.boolean(),
  isWelcomeEmailEnabled: z.boolean(),
  joinId: z.string(),
  longDescription: z.string(),
  programLength: z.number().nullable().optional(),
  programLengthUnit: DurationUnitTypeSchema.nullable().optional(),
  restrictedAccess: z.boolean(),
  shortDescription: z.string(),
  skills: z.array(SkillTagSchema),
  startDateUtc: z.string().nullable().optional(),
  status: CustomProgramStatusSchema,
  title: z.string(),
});
