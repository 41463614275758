// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Skills/Requests/AddSkillTagPayload.cs

import {z} from 'zod';

export const AddSkillTagPayloadSchema = z.object({
  entityId: z.number(),
  entityType: z.string(),
  language: z.string(),
  lxpInputId: z.number(),
  name: z.string(),
  organizationId: z.number(),
  skillTagId: z.number(),
  title: z.string(),
});
