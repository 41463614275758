// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/CustomProgramPayload.cs

import {z} from 'zod';
import {AttachmentPayloadSchema} from './AttachmentPayloadSchema.schema';
import {DurationUnitTypeSchema} from './DurationUnitTypeSchema.schema';
import {SkillTagSchema} from './SkillTagSchema.schema';

export const CustomProgramPayloadSchema = z.object({
  id: z.number().nullable().optional(),
  images: z.array(AttachmentPayloadSchema),
  imageUrl: z.string(),
  isNudgeEmailEnabled: z.boolean(),
  isProgramCompletionEmailEnabled: z.boolean(),
  isWelcomeEmailEnabled: z.boolean(),
  longDescription: z.string(),
  programLength: z.number().nullable().optional(),
  programLengthUnit: DurationUnitTypeSchema.nullable().optional(),
  restrictedAccess: z.boolean(),
  shortDescription: z.string(),
  skills: z.array(SkillTagSchema),
  title: z.string(),
});
