import {REQUEST_TEST_ID} from './Request.constants';
import {RequestViewProps} from './Request.interfaces';
import RequestMenuButtonAndDrawer from './RequestMenuButtonAndDrawer';

function RequestView({
  handleClickProgramFinder,
  isLoading,
  navigate,
  requestButtonText,
  showFindProgramOption,
  showProgramFinderOption,
  showRequestProgramOption,
  showRequestResourceOption,
}: RequestViewProps) {
  return (
    !isLoading && (
      <RequestMenuButtonAndDrawer
        buttonTitle={requestButtonText}
        data-testid={REQUEST_TEST_ID}
        onClickProgramFinder={() => {
          handleClickProgramFinder(navigate);
        }}
        showFindProgramOption={showFindProgramOption}
        showProgramFinderOption={showProgramFinderOption}
        showRequestProgramOption={showRequestProgramOption}
        showRequestResourceOption={showRequestResourceOption}
      />
    )
  );
}

export default RequestView;
