import {queryHookFactory, addQueryParams} from './../../utils';

import {z} from 'zod';

// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Skills/SkillsController.cs

/*
|--------------------------------------------------------------------------
|  associated/{type}/{resourceId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAssociatedByTypeQuery} from '@generated/hooks';
 *
 *  const {
 *    data: associatedByType,
 *    isLoading: isAssociatedByTypeLoading,
 *    invalidateExact: invalidateAssociatedByType,
 *  } = useAssociatedByTypeQuery({
 *    resourceId,
 *    type
 *  })
 *
 *  @access Admin Access Only
 *
 */

export const useAssociatedByTypeQuery = queryHookFactory<
  {resourceId: number; type: string}, // path variables
  boolean // return type
>(
  'useAssociatedByTypeQuery',
  ({type, resourceId}) => `skills/associated/${type}/${resourceId}`,
  {responseSchema: z.boolean()}
);

/*
|--------------------------------------------------------------------------
|  search/{companyId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useSearchByCompanyIdQuery} from '@generated/hooks';
 *
 *  const {
 *    data: searchByCompanyId,
 *    isLoading: isSearchByCompanyIdLoading,
 *    invalidateExact: invalidateSearchByCompanyId,
 *  } = useSearchByCompanyIdQuery({
 *    companyId,
 *      queryParams: {
 *        term
 *    }
 *  })
 *
 *  @access Admin Access Only
 *
 */

export const useSearchByCompanyIdQuery = queryHookFactory<
  {companyId: number | string; queryParams?: {term?: string}}, // path variables
  boolean // return type
>(
  'useSearchByCompanyIdQuery',
  ({companyId, queryParams}) =>
    addQueryParams(
      ({companyId}) => `skills/search/${companyId}`,
      {companyId},
      queryParams
    ),
  {responseSchema: z.boolean()}
);
