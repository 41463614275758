import i18next from 'i18next';
import {datadogLogs} from '@datadog/browser-logs';
import {
  LANGUAGE_DEFAULT_NAMESPACE,
  LANGUAGE_FALLBACK_LOCALE,
  LANGUAGE_LOCAL_STORAGE_KEY,
} from '@utils/constants';
import {customEscape} from '@utils/l10nUtils';
import {getLazyLoadableLangFiles} from './modulesUtil';

/*
|--------------------------------------------------------------------------
| Helpers
|--------------------------------------------------------------------------
*/

export const loadTranslation = (languageCode: string) => {
  if (!languageCode) {
    return;
  }
  try {
    const lazyLoadLocaleFile = getLazyLoadableLangFiles(languageCode);

    if (!lazyLoadLocaleFile) {
      throw new Error();
    }

    lazyLoadLocaleFile().then(({default: data}) => {
      i18next.addResourceBundle(languageCode, LANGUAGE_DEFAULT_NAMESPACE, data);
    });
  } catch (error) {
    datadogLogs.logger.error(
      `Failed to load the language file for: ${languageCode}`
    );
  }
};

const updateDocumentLanguage = (storedLanguage: string) => {
  document.documentElement.setAttribute(
    'lang',
    storedLanguage.indexOf('-') > -1
      ? storedLanguage.split('-')[0] // Split to use the 2-char ISO
      : storedLanguage // Already a 2-char ISO
  );
};

/*
|--------------------------------------------------------------------------
| Interfaces
|--------------------------------------------------------------------------
*/

export interface I18nextTranslationResource {
  [key: string]: string;
}

export interface I18nextTranslationResources {
  [key: string]: {translation: I18nextTranslationResource};
}

/*
|--------------------------------------------------------------------------
| Initialization
|--------------------------------------------------------------------------
*/

/** Returns a configured and initialized i18n instance */
export function i18nextInit() {
  let storedLanguage = localStorage.getItem(LANGUAGE_LOCAL_STORAGE_KEY);
  if (!storedLanguage) {
    storedLanguage = LANGUAGE_FALLBACK_LOCALE;
    localStorage.setItem(LANGUAGE_LOCAL_STORAGE_KEY, storedLanguage);
  }

  // Configure this translation loader instance
  i18next.init({
    debug: false, // flip for diagnosing
    partialBundledLanguages: true,
    resources: {}, // these are added async via loadTranslation()
    lng: storedLanguage,
    fallbackLng: [LANGUAGE_FALLBACK_LOCALE],
    interpolation: {
      escape: customEscape,
    },
  });

  // Async load the language files
  if (storedLanguage && LANGUAGE_FALLBACK_LOCALE !== storedLanguage) {
    loadTranslation(storedLanguage);
  }

  // Async load the fallback
  loadTranslation(LANGUAGE_FALLBACK_LOCALE);

  // Update the document lang attr to match the active language
  updateDocumentLanguage(storedLanguage);

  // Return the configured and initialized instance
  return i18next;
}
