// Do not modify directly
// Generated from file: learnin.core.api/Controllers/LxpContent/Responses/LxpContentResponse.cs

import {z} from 'zod';
import {LxpContentTypeSchema} from './LxpContentTypeSchema.schema';

export const LxpContentResponseSchema = z.object({
  contentType: LxpContentTypeSchema,
  description: z.string(),
  durationUnitType: z.string().nullable().optional(),
  durationValue: z.number().nullable().optional(),
  format: z.string(),
  id: z.number(),
  imageUrl: z.string().nullable().optional(),
  link: z.string(),
  skills: z.array(z.string()),
  title: z.string(),
});
