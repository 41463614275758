// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Enums/LanguagePreference.cs

enum LanguagePreference {
  EN_US,
  FR_CA,
  ES_419,
  PT_BR,
  DE_DE,
  EN_GB,
  FR_FR,
  JA_JP,
  ZH_CN,
  DA_DK,
  ES_ES,
  IT_IT,
  KO_KR,
  NL_NL,
  PL_PL,
  RU_RU,
  SV_SE,
}

export default LanguagePreference;
