export const isTestRun = import.meta?.env?.NODE_ENV === 'test';
export const environment = import.meta.env.VITE_ENV;

// Local
export const isLocal = import.meta.env.VITE_ENV === 'development';
export const isLocalDB = import.meta.env.VITE_DB === 'local';

// Dev
export const isDevelopmentAWS = import.meta.env.VITE_ENV === 'developmentAWS';
export const isDevelopmentAzure =
  import.meta.env.VITE_ENV === 'developmentAzure';
export const isDevelopmentAny =
  import.meta.env.VITE_ENV === 'developmentAWS' ||
  import.meta.env.VITE_ENV === 'developmentAzure';

// Staging
export const isStagingAWS = import.meta.env.VITE_ENV === 'stage';
export const isStagingAzure = import.meta.env.VITE_ENV === 'stageAzure';
export const isStagingAny =
  import.meta.env.VITE_ENV === 'stage' ||
  import.meta.env.VITE_ENV === 'stageAzure';

// Beta
export const isBetaEU = import.meta.env.VITE_ENV === 'betaEU';
export const isBetaUS = import.meta.env.VITE_ENV === 'betaUS';
export const isBetaAny = isBetaEU || isBetaUS;

// Production
export const isProductionUS = import.meta.env.VITE_ENV === 'production';
export const isProductionEU = import.meta.env.VITE_ENV === 'productionEU';
export const isProductionAny =
  import.meta.env.VITE_ENV === 'production' ||
  import.meta.env.VITE_ENV === 'productionEU';
