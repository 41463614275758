import {User, UserManager, Log} from 'oidc-client-ts';
import {
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react';
import {api} from '@store/api';
import UrlConfig from '../UrlConfig';

Log.setLevel(Log.WARN);
Log.setLogger(console);
const urlConfig = new UrlConfig();
const userManager: UserManager = new UserManager(urlConfig.AUTH_CONFIG);

export const getUser = (): Promise<User | null> => {
  return userManager.getUser();
};

export const login = (): Promise<void> => {
  //if ssoprovider query string then initate sso
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const ssoProvider = params.get('ssoprovider');

  if (ssoProvider) {
    return userManager.signinRedirect({
      state: window.location.href,
      extraQueryParams: {
        ssoprovider: ssoProvider,
      },
    });
  }
  return userManager.signinRedirect({
    state: window.location.href,
  });
};

export const renewSilently = () => {
  return userManager.startSilentRenew();
};

export const logout = async (): Promise<void> => {
  const token = await userManager.getUser();
  return userManager.signoutRedirect({id_token_hint: token.id_token});
  // args passed above are necessary for identity server to log you out and update query params on login page so you
  // can log back in
};

userManager.events.addSilentRenewError((error) => {
  console.error('Hit an error doing renew', error);

  userManager.getUser();

  // if we can't renew their token we should take them to the login page so they don't have a broken experience
  login();
});

userManager.events.addUserSignedOut(() => {
  console.log('User signed out event');
  // the user's login status changed so we need to send them to the login screen
  login();
});

const getUserInfo = async () => {
  try {
    const {data} = await api.get('user/get-user');
    if (data && data.message) {
      throw Error(data.message);
    }
    return {...{name: `${data?.firstName} ${data?.lastName}`}, ...data};
  } catch (error) {
    console.log('ERR: ', error);
  }
};

const AuthContext = createContext({
  /* eslint-disable @typescript-eslint/no-empty-function */
  login: () => {},
  logout: () => {},
  isAuthenticated: false,
  loginWithRedirect: () => {},
  user: null,
  isAdmin: false,
  isManager: false,
  accessToken: null,
  /* eslint-enable @typescript-eslint/no-empty-function */
});

export const useAuth = () => useContext(AuthContext);

interface AuthProviderProps {
  children: ReactElement;
}

export const AuthProvider = ({children}: AuthProviderProps) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState();
  const [isAdmin, setIsAdmin] = useState(false);
  const [isManager, setIsManager] = useState(false);
  const [accessToken, setAccessToken] = useState(null);

  const setLearnInUserInfo = async () => {
    const learnInUser = await getUserInfo();
    if (learnInUser) {
      setUser(learnInUser);
      setIsAdmin(learnInUser.isAdmin);
      setIsManager(learnInUser.isManager);
    }
  };

  const setUserLoginInfo = async (user) => {
    await setLearnInUserInfo();
    setAccessToken(user.id_token);
    setIsAuthenticated(true);
  };

  const initAuth = async () => {
    const currentUser = await getUser();
    if (currentUser) {
      await setUserLoginInfo(currentUser);
      return;
    }
    await login();
    const user = await getUser();
    if (!user) {
      setIsAuthenticated(false);
      return;
    }
    await setUserLoginInfo(user);
  };

  useEffect(() => {
    initAuth();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        login: (...p) => login(...p),
        logout: (...p) => logout(...p),
        loginWithRedirect: (...p) => login(...p),
        isAuthenticated,
        user,
        isAdmin,
        isManager,
        accessToken,
      }}>
      {children}
    </AuthContext.Provider>
  );
};
