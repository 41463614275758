import * as React from 'react';
import {Routes} from 'react-router-dom';
import LoadingSpinner from './elements/loadingSpinner/LoadingSpinner';
import CardIssuerTerms from '@components/CardIssuerTerms';
import styled from 'styled-components';
import DesktopSideNav from '@blocks/appNav/DesktopSideNav';
import MobileAppHeaderWithMobileMenu from '@blocks/appNav/MobileAppHeaderWithMobileMenu';
import {Breakpoints} from '@utils/responsiveUtils';
import {COLORS} from '@utils/constants';
import {Theme} from '@utils/Theme';
import DatadogErrorBoundary from '@components/reusable/ErrorBoundary/DataDogErrorBoundary';
import PrimaryRoutesShared from './PrimaryRoutesShared';
import useGetEnabledIntegratedPrimaryRoutes from '@hooks/useGetEnabledIntegratedPrimaryRoutes';
import {useBuildRouteElements} from '@hooks/useBuildRouteElements';
import {useIsLxpAdmin} from '@hooks/integrated/useIsLxpAdmin';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const MainContentContainer = styled.main<{fullWidth?: boolean}>`
  position: fixed;
  left: ${({fullWidth}) => (fullWidth ? 0 : '272px')};
  right: 0;
  bottom: 0;
  top: 0;
  overflow-y: auto;
  background: ${COLORS.Neutral50};
  @media (max-width: ${Breakpoints.Desktop}px) {
    left: 0;
    top: 68px;
  }
`;

/*
|--------------------------------------------------------------------------
| Main Routes Component
|--------------------------------------------------------------------------
*/
interface PrimaryRoutesProps {
  flagsReady: boolean;
  hidePrimaryNavigation: boolean;
  isAdmin: boolean;
  isApprover: boolean;
  isIntegratedUser: boolean;
  isManager: boolean;
}

const PrimaryRoutes = ({
  flagsReady,
  hidePrimaryNavigation,
  isAdmin,
  isApprover,
  isIntegratedUser,
  isManager,
}: PrimaryRoutesProps) => {
  const integratedRoutes = useGetEnabledIntegratedPrimaryRoutes({
    isAdmin,
    isApprover,
    isIntegratedUser,
    isManager,
  });
  const isLxpAdmin = useIsLxpAdmin();

  const integratedPrimaryRouteElements =
    useBuildRouteElements(integratedRoutes);

  return !flagsReady ? (
    <LoadingSpinner />
  ) : (
    <>
      <CardIssuerTerms />
      {(!hidePrimaryNavigation || isLxpAdmin) && (
        <>
          <DesktopSideNav /> <MobileAppHeaderWithMobileMenu />
        </>
      )}
      <MainContentContainer
        id="main"
        tabIndex="-1"
        fullWidth={hidePrimaryNavigation || isLxpAdmin}>
        <DatadogErrorBoundary>
          <Theme>
            <React.Suspense fallback={<LoadingSpinner />}>
              {isIntegratedUser ? (
                <Routes>{integratedPrimaryRouteElements}</Routes>
              ) : (
                <PrimaryRoutesShared isAdmin={isAdmin} isManager={isManager} />
              )}
            </React.Suspense>
          </Theme>
        </DatadogErrorBoundary>
      </MainContentContainer>
    </>
  );
};

export default PrimaryRoutes;
