import {useForceHidePrimaryNavigation} from '@hooks/integrated/useForceHidePrimaryNavigation';
import {useHidePrimaryNavigation} from '@hooks/integrated/useHidePrimaryNavigation';
import {useIsIntegratedUser} from '@hooks/integrated/useIsIntegratedUser';
import {useCanManageApprovals} from '@hooks/useCanManageApprovals';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {useFlagsHaveLoaded} from '@hooks/useFlagsHaveLoaded';
import {useHandleUrlPrefix} from '@hooks/useHandleUrlPrefix';
import {CheckUserStatusResponseData} from '@models/serverModels';
import {getCheckUserStatusRq} from '@store/apiEndpoints';
import {cacheForHours, simpleQueryFn} from '@store/queryClient';
import {initZenDesk} from '@utils/chat/chat-utils';
import {initCredSpark} from '@utils/credSpark/credspark-utils';
import {useAuth} from '@utils/oidc-auth-wrapper';
import PrimaryRoutes from 'PrimaryRoutes';
import {useEffect} from 'react';
import {useQuery} from 'react-query';

const PrimaryRoutesContainer = ({}) => {
  const {isFeatureFlagOn} = useFeatureFlags();
  const {user} = useAuth();

  const flagsReady = useFlagsHaveLoaded(isFeatureFlagOn);
  const isCredSparkDemoEnabled = isFeatureFlagOn.CredsparkDemo;
  const isChatEnabled = !isFeatureFlagOn.HideChatWidget;
  const isApprover = useCanManageApprovals(user);
  const isIntegratedUser = useIsIntegratedUser(
    isFeatureFlagOn.UpdatedLxpToCmFlow,
    user
  );
  const forceNavOff = useForceHidePrimaryNavigation(
    user,
    isFeatureFlagOn,
    isApprover
  );

  const hidePrimaryNavigation = useHidePrimaryNavigation({
    user,
    location: window.location,
    forceOff: forceNavOff,
  });

  const checkUserStatusRq = getCheckUserStatusRq();
  useQuery<CheckUserStatusResponseData>(
    checkUserStatusRq.queryKey,
    () => simpleQueryFn(checkUserStatusRq.path),
    {
      ...cacheForHours(6),
    }
  );

  useEffect(() => {
    if (!flagsReady) {
      return;
    }
    if (isChatEnabled) {
      initZenDesk(user);
    }
    if (isCredSparkDemoEnabled) {
      initCredSpark();
    }
  }, [flagsReady, isCredSparkDemoEnabled, isChatEnabled, user]);

  const handleUrlPrefix = useHandleUrlPrefix(isIntegratedUser);
  useEffect(() => {
    flagsReady && handleUrlPrefix();
  }, [flagsReady, handleUrlPrefix]);

  return (
    <PrimaryRoutes
      flagsReady={flagsReady}
      hidePrimaryNavigation={hidePrimaryNavigation}
      isAdmin={user?.isAdmin}
      isApprover={isApprover}
      isIntegratedUser={isIntegratedUser}
      isManager={user?.isManager}
    />
  );
};

export default PrimaryRoutesContainer;
