import {
  useCheckPublishedCustomProgramsExistQuery,
  useUserQuery,
} from '@generated/hooks';
import useFeatureFlags from './useFeatureFlags';
import {UseCanShowProgramsVM} from '@models/clientModels';

function useCanShowPrograms(): UseCanShowProgramsVM {
  const {isFeatureFlagOn} = useFeatureFlags();
  const {data: user} = useUserQuery(null);

  // make API call only if custom programs are enabled
  const {data: hasPublishedCustomPrograms} =
    useCheckPublishedCustomProgramsExistQuery(null, {
      enabled: user?.canViewAcademiesFeature && isFeatureFlagOn.CustomPrograms,
    });

  /**
   * :: ACA-2163 ::
   * Custom programs are deeply coupled with academies (if you pay for academies, you get to use custom programs)
   * Any time academies flag in the DB is “on” we should also show custom programs
   * Any time academies flag in the DB is “off” we should hide them
   * Update: With HideMarketplace Feature Flag if the marketplace is hidden, the user can still see academies if there are no custom programs.
   */
  return {
    customPrograms:
      user?.canViewAcademiesFeature && // Academies are enabled in the DB AND...
      isFeatureFlagOn.CustomPrograms && // Custom Programs are enabled AND
      !!hasPublishedCustomPrograms, // And there are available custom programs to show
    marketplace:
      !isFeatureFlagOn.OnlyCustomProgramsUser && // user is not restricted only to custom programs
      !isFeatureFlagOn.HideMarketplace && //marketplace is not hidden by feature flag
      !!user?.isEligibleForPersonalLearningBudget, // and is elegible for PLB
    get any() {
      // can display either CustomPrograms OR MarketPlace
      return this.customPrograms || this.marketplace;
    },
    get all() {
      // can display both CustomPrograms AND MarketPlace
      return this.customPrograms && this.marketplace;
    },
  };
}

export default useCanShowPrograms;
